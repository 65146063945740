import axios from 'axios';
import AuthService from './auth-header';

// /*dev*/const API_URL = 'http://192.168.1.46:5000/api/';
// /*prod*/ // const API_URL = 'https://ats.sattvahuman.in/api/';

let API_URL = ''
if (process.env.NODE_ENV === "production") {
    API_URL = 'https://ats.sattvahuman.in/api/';
} else {
    API_URL = 'http://192.168.1.46:5000/api/';
}
class locationService {

    async create(data) {
        try {
          const res = await axios.post(API_URL + 'locations', data,
            {
                headers: AuthService.authHeader()
            });
            console.log(res.data)
            return res.data
        }
        catch(err) {
            console.log(err.response);
            return err.reponse;
        }
    }

    async getAll() {
        try {
            const res = await axios.get(API_URL + 'locations',
            {
                headers: AuthService.authHeader()
            });
            console.log(res.data)
            return res.data
        }
        catch(err) {
            console.log(err.response);
            return err.reponse;
        }
    }
    
}

export default new locationService();